<template>
  <div class="create-batch-check-order-job">
    <breadcrumb style="margin-left: 15px; margin-bottom: -5px"></breadcrumb>
    <a-spin :spinning="loading">
      <div class="content flex-ct between" style="min-height: 0">
        <a-tabs default-active-key="OrderStatusFlow" @change="changeTabs">
          <a-tab-pane key="ArrivalDetail" :tab="$t(`基本信息`)"></a-tab-pane>
          <a-tab-pane key="AmountInfo" :tab="$t(`金额信息`)"></a-tab-pane>
          <a-tab-pane key="ProductInfo" :tab="$t(`明细`)"></a-tab-pane>
        </a-tabs>
        <div>
          <OperateBtn
            v-if="formData.status === 'WAIT_CHECK'"
            api="checkOrder"
            :text="$t('审核')"
            :params="{
              docType: 'PaymentNote',
              idList: [id],
            }"
            @success="init"
          />
          <OperateBtn
            v-if="formData.status === 'WAIT_PAY'"
            class="ml10"
            api="payPaymentNote"
            :text="$t('付款')"
            :params="{
              idList: [id],
            }"
            @success="init"
          />
          <OperateBtn
            v-if="isDetail && ['WAIT_CHECK', 'WAIT_PAY'].includes(formData.status)"
            class="ml10"
            api="cancelPaymentNote"
            :text="$t('作废')"
            type="danger"
            :params="{
              idList: [id],
            }"
            @success="init"
          />
          <VoucherBtn
            class="ml10"
            v-if="!['DRAFT', 'WAIT_CHECK', 'CANCELLED'].includes(formData.status)"
            docType="PaymentNote"
            :selectedRows="[formData]"
            @close="init"
          />
        </div>
      </div>
      <div class="content-overflow">
        <section class="left-div fixContent" :style="{ height: scrollHeight }">
          <a-form-model ref="ruleForm" :model="formData">
            <div id="ArrivalDetail" class="modalBox">
              <div class="infoTitle">
                <div>
                  <i class="leftLine"></i>
                  <span>{{ $t('基本信息') }}</span>
                  <span class="fz14 ml10 text-link" v-if="isDetail" @click="copyOrder">
                    {{ $t('复制单据') }}
                  </span>
                </div>
              </div>
              <a-row :gutter="16">
                <a-col :span="6">
                  <a-form-model-item :label="$t(`付款单号	`)">
                    <a-input disabled v-model="formData.paymentNoteSn"></a-input>
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`收款组织`)" required>
                    <SelectSupplier
                      ref="selectSupplier"
                      :placeholder="$t(`收款组织`)"
                      :code="formData.supplierCode"
                      :disabled="isDisabled"
                      @supplierIndexChange="supplierIndexChange"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`记账日期`)">
                    <DatePicker
                      :needhms="false"
                      style="background-color: #ffffff; width: 100%"
                      :init-date-time="formData.accountingDate"
                      :disabled="true"
                      :placehold="$t(`记账日期`)"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`结算组织`)" required>
                    <CommonQuerySelect
                      :init="true"
                      api="getBusinessUnitList"
                      :disabled="isDisabled"
                      :code.sync="formData.businessUnitCode"
                      @change="changeBU"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`单据类型`)" required>
                    <CommonSelect
                      :placeholder="$t(`单据类型`)"
                      :list="payment_note_type"
                      :code.sync="formData.orderType"
                      :disabled="isDisabled"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`备注`)">
                    <a-input
                      :disabled="isDisabled"
                      v-model="formData.remark"
                      :placeholder="$t(`备注`)"
                    ></a-input>
                  </a-form-model-item>
                </a-col>
                <a-col :span="6" v-if="isDetail">
                  <a-form-model-item :label="$t(`状态`)">
                    <a-input disabled v-model="paymentNoteStatusMapping[formData.status]"></a-input>
                  </a-form-model-item>
                </a-col>
                <a-col :span="6" v-if="isDetail">
                  <a-form-model-item :label="$t(`是否生成凭证`)">
                    <a-input
                      disabled
                      :value="+formData.voucherGenerated ? $t('是') : $t('否')"
                    ></a-input>
                  </a-form-model-item>
                </a-col>
              </a-row>
            </div>
            <div id="AmountInfo" class="modalBox">
              <div class="infoTitle">
                <div>
                  <i class="leftLine"></i>
                  <span>{{ $t('金额信息') }}</span>
                </div>
              </div>
              <a-row :gutter="16">
                <a-col :span="6">
                  <a-form-model-item :label="$t(`本位币`)" required>
                    <SelectCurrency :disabled="true" :code.sync="formData.functionalCurrency" />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`付款金额本位币`)">
                    <PriceInput
                      disabled
                      :currency="formData.functionalCurrency"
                      :value="formData.functionalPaymentAmount"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`付款货币`)" required>
                    <SelectCurrency
                      :code.sync="formData.paymentCurrency"
                      :disabled="isDisabled"
                      @change="changeCurrency"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`付款金额`)">
                    <PriceInput
                      disabled
                      :currency="formData.paymentCurrency"
                      :value="formData.paymentAmount"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`结算方式`)" required>
                    <SelectSettlementMethod
                      :code.sync="formData.settlementMethod"
                      :disabled="isDisabled"
                    />
                  </a-form-model-item>
                </a-col>
              </a-row>
            </div>
          </a-form-model>
          <div id="ProductInfo" class="modalBox">
            <div>
              <div class="infoTitle">
                <div>
                  <i class="leftLine"></i>
                  {{ $t('明细') }}
                </div>
                <div class="option-btns pr20" v-if="!isDisabled">
                  <span class="text-link fz16" @click="addLineHandle">
                    {{ $t('增行') }}
                  </span>
                  <span class="text-link fz16 ml20" @click="deleteLineHandle">
                    {{ $t('删行') }}
                  </span>
                </div>
              </div>
              <CommonTable
                :columns="columns"
                :dataSource="showTableList"
                :scroll="{ x: 1200 }"
                :showPagination="false"
                :showSelect="!isDisabled"
                :selectedRowKeys.sync="selectedRowKeys"
              >
                <template v-slot:no="{ index }">
                  {{ index + 1 }}
                </template>
                <template v-slot:paymentPurpose="{ record }">
                  <CommonSelect
                    :disabled="isDisabled"
                    :list="payment_purpose"
                    :code.sync="record.paymentPurpose"
                  />
                </template>
                <template v-slot:settlementCurrency="{ record }">
                  <SelectCurrency
                    :disabled="isDisabled || !!record.referDocDetailId"
                    :code.sync="record.settlementCurrency"
                  />
                </template>
                <template v-slot:payableAmount="{ record }">
                  <PriceInput
                    :disabled="isDisabled"
                    :currency="record.settlementCurrency"
                    :value.sync="record.payableAmount"
                    @blur="() => handleChange(record)"
                  />
                </template>
                <template v-slot:discountAmount="{ record }">
                  <PriceInput
                    :currency="record.settlementCurrency"
                    :disabled="isDisabled"
                    :value.sync="record.discountAmount"
                    @blur="() => handleChange(record)"
                  />
                </template>
                <template v-slot:paymentAmount="{ text }">
                  <PriceInput
                    v-if="text"
                    :currency="formData.paymentCurrency"
                    :value="text"
                    :showTxt="true"
                  />
                </template>
                <template v-slot:remark="{ record }">
                  <a-input v-model="record.remark"></a-input>
                </template>
              </CommonTable>
            </div>
          </div>
        </section>
      </div>
      <BottomBtns
        :isEdit.sync="isEdit"
        :isDetail="isDetail"
        :draftLoading="draftLoading"
        :saveLoading="saveLoading"
        :status="formData.status"
        @submit="onSubmit"
        @cancel="getDetailInfo"
        @back="backForm"
      />
    </a-spin>
    <a-modal :title="$t('单据转换报告')" v-model="resultVisible" width="800px">
      <CommonTable :columns="resultColumns" :dataSource="resultList" :showPagination="false">
        <template v-slot:no="{ index }">
          {{ index + 1 }}
        </template>
        <template v-slot:status="{ record }">
          <span class="text-success" v-if="record.success">
            {{ $t('成功') }}
          </span>
          <span class="text-error" v-else>
            {{ $t('失败') }}
          </span>
        </template>
      </CommonTable>
      <template slot="footer">
        <a-button @click="resultVisible = false">{{ $t('关闭') }}</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import moment from 'moment'
import { mapState, mapGetters } from 'vuex'
import http from '@/service/axios'
import api from '@/service/api'
import SelectSupplier from '@component/selectSupplier'
import SelectCurrency from '@component/selectCurrency'
import VoucherBtn from '@component/voucherBtn'
import SelectSettlementMethod from '@component/selectSettlementMethod'
import { add, subtract, multiply } from '@/common/number'
import DatePicker from '@component/datePicker'
import BottomBtns from '@component/bottomBtns'
import deepClone from '@/common/deepClone'
import { convertKeysToCamelCase } from '@/common'
import debounce from '@/common/debounce'
export default {
  name: 'paymentNoteDetail',
  components: {
    SelectSupplier,
    DatePicker,
    SelectCurrency,
    VoucherBtn,
    BottomBtns,
    SelectSettlementMethod,
  },
  data() {
    return {
      id: '',
      loading: false,
      visible: false,
      isCopy: false,
      isDetail: false, //是否是详情页
      isEdit: false, //是否编辑
      saveLoading: false,
      draftLoading: false,
      tableData: [],
      selectedRowKeys: [],
      formData: {
        businessUnitCode: '',
        businessUnitName: '',
        supplierCode: '',
        supplierName: '',
        orderType: undefined,
        amountWithoutTax: undefined,
        payableAmount: undefined,
        paymentAmount: undefined,
        functionalPaymentAmount: undefined,
        functionalCurrency: 'CNY',
        settlementCurrency: 'CNY',
        paymentCurrency: 'CNY',
        status: 'DRAFT',
      },
      purchaseAllQuantity: 0,
      purchaseAllAmount: 0,
      totalPayableAmount: 0,
      resultVisible: false,
      resultList: [],
    }
  },
  activated() {
    this.init()
  },
  beforeRouteUpdate(to, from, next) {
    Object.assign(this.$data, this.$options.data())
    this.$nextTick(() => {
      this.init()
    })
    next()
  },
  computed: {
    ...mapState(['payment_note_type', 'payment_purpose', 'currencyRateMapping']),
    ...mapGetters(['paymentNoteStatusMapping', 'currencySymbolMapping']),
    isDisabled() {
      return this.isDetail && !this.isEdit
    },
    columns() {
      let list = [
        {
          title: this.$t('付款用途'),
          align: 'center',
          dataIndex: 'paymentPurpose',
          width: 120,
          scopedSlots: {
            customRender: 'paymentPurpose',
          },
        },
        {
          title: this.$t('结算货币'),
          align: 'center',
          dataIndex: 'settlementCurrency',
          width: 150,
          scopedSlots: {
            customRender: 'settlementCurrency',
          },
        },
        {
          title: this.$t('应付金额'),
          align: 'center',
          dataIndex: 'payableAmount',
          width: 150,
          scopedSlots: {
            customRender: 'payableAmount',
          },
        },
        {
          title: this.$t('折扣'),
          align: 'center',
          dataIndex: 'discountAmount',
          width: 120,
          scopedSlots: {
            customRender: 'discountAmount',
          },
        },
        {
          title: this.$t('实付金额'),
          align: 'center',
          dataIndex: 'paymentAmount',
          width: 120,
          scopedSlots: {
            customRender: 'paymentAmount',
          },
        },
        {
          title: this.$t('备注'),
          align: 'center',
          dataIndex: 'remark',
          width: 120,
          scopedSlots: {
            customRender: 'remark',
          },
        },
      ]
      return list
    },
    scrollHeight() {
      let height = window.innerHeight - 300
      return height * this.$store.state.rate + 'px'
    },
    resultColumns() {
      return [
        {
          title: this.$t('源单数据'),
          dataIndex: 'orderSn',
        },
        {
          title: this.$t('下推状态'),
          scopedSlots: {
            customRender: 'status',
          },
        },
        {
          title: this.$t('消息'),
          dataIndex: 'desc',
        },
      ]
    },
    showTableList() {
      return this.tableData.filter((item) => item.showStatus !== -1)
    },
    currencyRate() {
      return this.currencyRateMapping[this.formData.paymentCurrency] || 1
    },
  },
  methods: {
    init() {
      this.isCopy = false
      this.isEdit = false
      this.isDetail = false
      if (this.$route.query.id) {
        this.id = this.$route.query.id
      } else {
        Object.assign(this.$data, this.$options.data())
        this.formData.accountingDate = moment().format('YYYY-MM-DD')
      }
      this.getDetailInfo()
    },
    changeBU(value) {
      this.formData.businessUnitCode = value.code
      this.formData.businessUnitName = value.name
      this.formData.functionalCurrency = value.currency
      this.formData.paymentCurrency = value.currency
    },
    scrollToBottom() {
      this.$nextTick(() => {
        document.querySelector('.fixContent').scrollTo(0, 2000)
      })
    },
    supplierIndexChange(val) {
      this.formData.supplierName = val.supplierName
      this.formData.supplierCode = val.supplierCode
      if (!this.formData.businessUnitCode) {
        this.formData.businessUnitCode = val.businessUnitCode
        this.formData.businessUnitName = val.businessUnitName
      }
    },
    async getDetailInfo() {
      this.loading = true
      let paymentNoteFormdata = localStorage.getItem('paymentNoteFormdata'),
        pushPayableList = localStorage.getItem('pushPayableList')
      if (this.$route.query.id) {
        this.isDetail = true
        let res = await http({
          url: api.getPaymentNoteDetail,
          type: 'post',
          data: {
            id: this.id,
          },
        })
        let result = convertKeysToCamelCase(res.result)
        this.tableData = deepClone(result.detailList)
        if (this.tableData.length === 0) {
          this.addLineHandle()
        }
        let formData = result
        this.formData = formData
        this.calcAllAmount()
        if (result.status === 'DRAFT') {
          this.isEdit = true
        }
      } else if (paymentNoteFormdata) {
        //复制
        this.isCopy = true
        let data = JSON.parse(paymentNoteFormdata)
        console.log(data)
        this.formData = data
        this.formData.paymentNoteSn = undefined
        this.formData.id = undefined
        this.formData.status = 'DRAFT'
        this.tableData = deepClone(this.formData.tableData)
        delete this.formData.tableData
        localStorage.removeItem('paymentNoteFormdata')
      } else if (pushPayableList) {
        //应付单下推过来
        let data = this.getTableData(JSON.parse(pushPayableList))
        this.tableData = deepClone(data)
        localStorage.removeItem('pushPayableList')
        this.calcAllAmount()
      }
      this.loading = false
    },
    // 待核销/部分核销状态 planList里面status: "NORMAL"可以下推生成付款申请单、付款单
    getTableData(list) {
      this.formData.supplierCode = list[0].supplierCode
      this.formData.supplierName = list[0].supplierName
      this.formData.businessUnitCode = list[0].businessUnitCode
      this.formData.businessUnitName = list[0].businessUnitName
      this.formData.paymentCurrency = list[0].paymentCurrency
      this.formData.dueDate = list[0].dueDate
      let result = [],
        data = []
      let referDocType = localStorage.getItem('referDocType') || 'PayableOrder'
      list.forEach((item) => {
        if (
          referDocType === 'PayableOrder' &&
          !['WAIT_WRITTEN_OFF', 'PART_WRITTEN_OFF'].includes(item.status)
        ) {
          result.push({
            orderSn: item.orderSn,
            success: false,
            desc: this.$t('不符合条件: 请选择待核销、部分核销单据'),
          })
          return
        }
        if (referDocType !== 'PayableOrder' && !['WAIT_PAY', 'PART_PAID'].includes(item.status)) {
          result.push({
            orderSn: item.orderSn,
            success: false,
            desc: this.$t('不符合条件: 请选择待付款、部分付款单据'),
          })
          return
        }
        let planResult = [],
          hasError = false
        let tableList = item.planList || item.detailList || item.detail_list || []
        tableList.forEach((elem, index) => {
          let unLockAmount = 0
          if (referDocType === 'PaymentRequest') {
            unLockAmount = subtract(elem.approvedAmount, elem.lockAmount || 0)
          } else {
            unLockAmount = subtract(elem.payableAmount, elem.lockAmount || 0)
          }
          console.log('unLockAmount: ', unLockAmount)
          console.log('elem', elem)
          if ((!elem.status || elem.status === 'NORMAL') && unLockAmount > 0) {
            data.push({
              dueDate: moment().format('YYYY-MM-DD'),
              payableAmount: elem.applyAmount || elem.payableAmount,
              discountAmount: '',
              paymentAmount: elem.applyAmount || elem.payableAmount,
              paymentPurpose: elem.isPrepay ? 'PREPAYMENT' : 'PURCHASE',
              referDocDetailId: elem.id,
              referDocId: item.id,
              referDocSn: item.orderSn,
              referDocType,
              sourceDocDetailId: elem.sourceDocDetailId || elem.id,
              sourceDocId: elem.sourceDocId || item.id,
              sourceDocSn: elem.sourceDocSn || item.orderSn,
              sourceDocType: elem.sourceDocType || referDocType,
              settlementCurrency: elem.settlementCurrency || item.settlementCurrency,
            })
            planResult.push({
              orderSn: item.orderSn + this.$t('付款计划第X行', { X: index + 1 }),
              success: true,
              desc: this.$t('符合条件'),
            })
          } else if (elem.status && elem.status !== 'NORMAL') {
            planResult.push({
              orderSn: item.orderSn + this.$t('付款计划第X行', { X: index + 1 }),
              success: false,
              desc: this.$t('不符合条件: 付款计划状态需为未冻结'),
            })
            hasError = true
          } else if (unLockAmount <= 0) {
            planResult.push({
              orderSn: item.orderSn + this.$t('付款计划第X行', { X: index + 1 }),
              success: false,
              desc: this.$t('不符合条件: 付款计划未锁定金额需大于0'),
            })
            hasError = true
          }
        })
        if (hasError) {
          result = [...result, ...planResult]
        } else {
          result.push({
            orderSn: item.orderSn,
            success: true,
            desc: this.$t('符合条件'),
          })
        }
      })
      this.resultList = result
      this.resultVisible = true
      return data
    },
    handleChange(record) {
      if (
        !this.$isEmpty(record.discountAmount) &&
        Math.abs(+record.discountAmount) > Math.abs(+record.payableAmount)
      ) {
        this.$message.warning(this.$t('折扣金额不能大于应付金额'))
        record.discountAmount = record.payableAmount
      }
      // this.$set(record, 'discountAmount', record.discountAmount)
      let paymentAmount = multiply(
        subtract(record.payableAmount, record.discountAmount || 0),
        this.currencyRate
      ).toFixed(4)
      this.$set(record, 'paymentAmount', Number(paymentAmount))
      this.calcAllAmount()
    },
    calcAllAmount() {
      let functionalPaymentAmount = 0,
        payableAmount = 0,
        paymentAmount = 0
      this.tableData.forEach((item) => {
        functionalPaymentAmount = add(
          functionalPaymentAmount,
          subtract(item.payableAmount, item.discountAmount) || 0
        )
        payableAmount = add(payableAmount, item.payableAmount || 0)
        paymentAmount = add(paymentAmount, item.paymentAmount || 0)
      })
      this.formData.functionalPaymentAmount = functionalPaymentAmount //付款金额本位币
      this.formData.paymentAmount = paymentAmount //付款金额
      this.formData.payableAmount = payableAmount //应付金额
    },
    verification() {
      this.tableData.forEach((res, index) => {
        if (!res.paymentPurpose) {
          this.$warning({
            title: this.$t(`layer.Warning`),
            content: this.$t(`第X行请选择付款用途`, { X: index + 1 }),
          })
          throw Error()
        }
      })
      return Promise.resolve()
    },
    backForm(back = 0) {
      if (back) {
        this.$router.go(-1)
        return
      }
      this.$router.push({ name: 'paymentNoteList' })
    },
    changeTabs(value) {
      document.querySelector(`#${value}`).scrollIntoView(true)
    },
    copyOrder() {
      this.formData.tableData = this.tableData
      let data = JSON.stringify(this.formData)
      localStorage.setItem('paymentNoteFormdata', data)
      this.$router.push('/payableManage/paymentNoteDetail')
    },
    chooseDueDate(record, data) {
      record.dueDate = data.data
    },
    onSubmit: debounce(async function (status) {
      if (status !== 'DRAFT') {
        if (!this.formData.supplierName) {
          this.$warning({
            title: this.$t(`layer.Warning`),
            content: this.$t(`请选择收款组织`),
          })
          return
        }
        await this.verification()
      }
      const data = deepClone(this.formData)
      if (this.id) {
        data.id = this.id
      }
      data.status = status
      if (status === 'DRAFT') {
        this.draftLoading = true
      } else {
        this.saveLoading = true
      }
      data.detailList = deepClone(this.tableData).map((item) => {
        item.paymentCurrency = this.formData.paymentCurrency
        return item
      })
      data.merchantCode = localStorage.getItem('merchantCode')
      await http({
        url: api.createPaymentNote,
        data: {
          paymentNoteReqVO: data,
        },
        type: 'post',
        hasLoading: true,
        success: (res) => {
          if (res.success) {
            this.$success({
              title: this.$t(`layer.Success`),
              content: this.$t(`layer.SavingSucceeded`),
            })
            if (this.isCopy) {
              this.backForm()
            } else {
              this.init()
            }
          } else {
            this.$error({
              title: this.$t(`layer.Error`),
              content: this.$t(`layer.CreateFailed`),
            })
          }
        },
      })
      this.draftLoading = false
      this.saveLoading = false
    }),
    addLineHandle() {
      this.tableData.push({
        payableAmount: '',
        discountAmount: '',
        settlementCurrency: this.formData.functionalCurrency,
        dueDate: moment().format('YYYY-MM-DD'),
      })
    },
    deleteLineHandle() {
      if (this.selectedRowKeys.length === 0) {
        this.$message.warning(this.$t(`至少选中一项`))
        return
      }
      this.tableData
        .filter((item) => item.showStatus !== -1)
        .forEach((item, index) => {
          if (this.selectedRowKeys.includes(index)) {
            this.$set(item, 'showStatus', -1)
            item.payableAmount = 0
            item.applyAmount = 0
            item.expectedPaymentAmount = 0
          }
        })
      this.selectedRowKeys = []
      this.calcAllAmount()
    },
    changeCurrency() {
      this.tableData.forEach((item) => {
        if (item.paymentAmount) {
          item.paymentAmount = Number(
            multiply(subtract(item.payableAmount, item.discountAmount), this.currencyRate).toFixed(
              4
            )
          )
        }
      })
      this.calcAllAmount()
    },
  },
}
</script>

<style lang="scss" scoped>
.statusBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form-table-heard:before {
  content: '*';
  color: red;
}

.form-content {
  padding: 20px 5%;
}
.ant-form-item {
  margin-bottom: 0;
}
.search-group {
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 3px;
  .add {
    position: absolute;
    display: block;
    right: -45px;
  }
}
.goods-list {
  flex-wrap: wrap;
  .goods-item {
    line-height: normal;
    margin-right: 6px;
    margin-bottom: 4px;
    white-space: nowrap;
  }
}
.infoTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fixContent {
  padding-top: 0;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 0;
  padding-right: 16px;
}
.node-modal {
  font-size: 15px;
  padding-top: 10px;
  text-align: center;
}
.content-overflow {
  width: 100%;
  overflow: auto;
  display: flex;
}
.left-div {
  flex: 1;
}
.right-div {
  background-color: transparent;
  font-size: 12px;
  width: 300px;
  height: 591px;
  overflow: auto;
}
.formContentGoods {
  /* padding: 10px 15px; */
  margin-bottom: 16px;
  background-color: white;
  color: #333333;
  font-size: 14px;
}
.modalA ::v-deep .ant-modal-footer {
  border-top: 0px solid #e8e8e8;
}
.modalBox {
  margin-bottom: 10px;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
}
.pricing-method {
  margin-bottom: 10px;
}
</style>
